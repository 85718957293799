import React from 'react'
import PropTypes from 'prop-types'
import { ShareButtonCircle, ShareBlockStandard } from 'react-custom-share'

import {FaTwitter, FaFacebook, FaPinterest, FaEnvelope, FaLinkedin} from "react-icons/fa";


const CustomReactShare = props => {
  const { url, title, excerpt, image } = props
  
  const shareBlockProps = {
    url: url,
    button: ShareButtonCircle,
    buttons: [
      { network: 'Twitter', icon: FaTwitter },
      { network: 'Facebook', icon: FaFacebook },
      { network: 'Email', icon: FaEnvelope },
      {
        network: 'Pinterest',
        icon: FaPinterest,
        media: image,
      },
      { network: 'Linkedin', icon: FaLinkedin },
    ],
    text: title,
    longtext: excerpt,
  }

  return <ShareBlockStandard {...shareBlockProps} />
}

CustomReactShare.PropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  image: PropTypes.string
}

export default CustomReactShare
